import React from 'react'
import { NavLink } from 'react-router-dom'
import './ProductDetail.css'
import backgroundOnEarthStudio from './images/backgroundonearthstudio_02.png'

function ProductDetail(props) {
  const { productDetail, onAdd } = props
  return (
    <div className='product__detail'>
      <img
        className='products__image'
        src={backgroundOnEarthStudio}
        alt='background'
      />
      <div className='product__detail__container'>
        <div className='product__detail__background'>
          <h3 className='product__detail__title'>{productDetail.title}</h3>
          <h6>{productDetail.comment}</h6>
          <h4>{productDetail.subtitle}</h4>
          <p>
            <small>$</small>
            <strong>{productDetail.price.toFixed(2)}</strong>
          </p>
          <NavLink to='/products'>
            <img
              id='product__image__detail'
              src={productDetail.image}
              alt={productDetail.subtitle}
            />
          </NavLink>
          <button
            className='product__button'
            onClick={() => onAdd(productDetail)}
          >
            ADD TO CART
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
