import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './About.css'
import backgroundOnEarthStudio from './images/backgroundonearthstudio_02.png'
import oe01 from './images/oe01.jpg'
import oe02 from './images/oe02.jpg'
import oe03 from './images/oe03.jpg'
import oe04 from './images/oe04.jpg'
import oe05 from './images/oe05.jpg'
import oe06 from './images/oe06.jpg'
import oe07 from './images/oe07.jpg'
import oe08 from './images/oe08.jpg'

function About() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = monthNames[new Date().getMonth()]
  const day = new Date().getDate()
  const year = new Date().getFullYear()
  const [localTimeSeconds, setLocalTimeSeconds] = useState(
    new Date().toLocaleTimeString()
  )

  useEffect(() => {
    let oneSecTimer = setInterval(() => {
      setLocalTimeSeconds(new Date().toLocaleTimeString())
    }, 1000)
    return () => clearInterval(oneSecTimer)
  }, [])

  return (
    <div className='about'>
      <img
        className='about__image'
        src={backgroundOnEarthStudio}
        alt='background'
      />
      <div className='about__container_01'>
        <NavLink to='/contact' className='cursor__default'>
          <h2 className='about__title'>About On Earth Studio</h2>
          <div className='today__s__date'>
            <h5>
              <i>
                Today's date on earth: {month} {day}, {year}
              </i>
            </h5>
            <h6>
              <i>{localTimeSeconds}</i>
            </h6>
          </div>
        </NavLink>
      </div>
      <div className='about__container_02'>
        <NavLink to='/' className='cursor__default'>
          <h2 className='about__title'>
            ON EARTH STUDIO CREATES WEBSITES, 3D ANIMATIONS AND MOVING IMAGES
            DESIGN
          </h2>
        </NavLink>
      </div>
      <div className='about__container_03'>
        <NavLink to='/contact' className='cursor__default'>
          <p id='listening__justify'>
            <strong>LISTENING</strong>
          </p>
        </NavLink>
        <NavLink to='/' className='cursor__default'>
          <p id='visualizing__justify'>
            <strong>VISUALIZING</strong>
          </p>
        </NavLink>
        <NavLink to='/products' className='cursor__default'>
          <p id='creating__justify'>
            <strong>CREATING</strong>
          </p>
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe01}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe02}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe03}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe04}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe05}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe06}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe07}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
      <div className='about__container_04'>
        <NavLink to='/contact' className='cursor__default'>
          <img
            className='on__earth__image'
            src={oe08}
            alt='what needs to be done?'
          />
        </NavLink>
      </div>
    </div>
  )
}

export default About
