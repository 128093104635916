import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { init, sendForm } from 'emailjs-com'
import './ContactForm.css'
import backgroundOnEarthStudio from './images/backgroundonearthstudio_02.png'

init('user_4146ex31SszAiqqsp4Q1q')

function ContactForm() {
  const [statusMessage, setStatusMessage] = useState('')
  const { register, handleSubmit, watch } = useForm()
  const [contactNumber, setContactNumber] = useState('000000')
  const generateContactNumber = () => {
    const numStr = '000000' + ((Math.random() * 1000000) | 0)
    setContactNumber(numStr.substring(numStr.length - 6))
  }
  const onSubmit = (data) => {
    const statusMessage = document.querySelector('.status-message')
    const form = document.querySelector('#emailjs__form')
    // console.log(data)
    generateContactNumber()
    sendForm(
      'oes_contact_form',
      'contact_form_template_ID',
      '#emailjs__form'
    ).then(
      function (response) {
        console.log('SUCCESS!', response.status, response.text)
        setStatusMessage('Message sent! We should be in touch shortly.')
        statusMessage.className = 'status-message success'
        setTimeout(() => {
          statusMessage.className = 'status-message'
        }, 5500)

        form.reset()
      },
      function (error) {
        setStatusMessage('Failed to send message! Please try again later.')
        statusMessage.className = 'status-message failure'
        setTimeout(() => {
          statusMessage.className = 'status-message'
        }, 5500)

        console.log('FAILED...', error)
      }
    )
  }
  const message = watch('message') || ''
  const messageCharsLeft = 2001 - message.length

  return (
    <div className='contact'>
      <img
        className='contact__image'
        src={backgroundOnEarthStudio}
        alt='background'
      />
      <div className='contact__form__container'>
        <h2 className='contact__form__title'>Contact Form</h2>
        <p className='status-message'>
          <i>{statusMessage}</i>
        </p>
        <form
          id='emailjs__form'
          className='contact__form__form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type='hidden' name='contact_number' value={contactNumber} />
          <label>First Name</label>
          <input
            width='100%'
            type='text'
            name='user_firstname'
            maxlenght='30'
            // aria-invalid={errors.user_name ? 'true' : 'false'}
            autoComplete='off'
            {...register('user_firstname', {
              required: 'Required',
            })}
            placeholder='Your First Name'
          />

          <label>Last Name</label>

          <input
            type='text'
            name='user_lastname'
            maxlenght='30'
            autoComplete='off'
            {...register('user_lastname', {
              required: 'Required',
            })}
            placeholder='Your Last Name'
          />

          <label>Email</label>
          <input
            type='email'
            name='user_email'
            maxlenght='30'
            autoComplete='off'
            {...register('user_email', {
              required: 'Email is required',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email',
              },
            })}
            placeholder='Your Email'
          />

          <label htmlFor='message'>Your Message</label>
          <textarea
            maxLength='2001'
            className='contact__form__your__message'
            name='message'
            autoComplete='off'
            {...register('message', {
              required: 'Required',
            })}
            placeholder=' Write Your Message Here'
          ></textarea>
          <p className='chars__left'>{messageCharsLeft}</p>

          <input
            type='submit'
            className='send__your__message__button'
            value='Send Your Message'
          />
        </form>
      </div>
    </div>
  )
}

export default ContactForm
