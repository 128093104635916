const data = {
  products: [
    {
      id: '1',
      title: ' 4K IMAGE On Earth Front',
      subtitle: "What need's to be done?",
      price: 8.5,
      image: 'https://onearth.studio/static/media/lowresonearthfront.jpg',
      comment: 'Front redlight RGB PNG 3840x2400px 72dpi',
      forcheckout: 'Front redlight',
    },
    {
      id: '2',
      title: 'THIS SHOPPING WEBSITE',
      subtitle: 'Customized for You - Using Stripe',
      price: 1950,
      image: 'https://onearth.studio/static/media/allwebsitesmall_02.jpg',
      comment:
        'Everything will be changed to make it yours: Navbar, links to social media, logo, contact form, products, pictures,...',
      forcheckout: 'React JS - Stripe - Customized',
    },
    {
      id: '3',
      title: ' 4K IMAGE On Earth Back',
      subtitle: "What need's to be done?",
      price: 8.5,
      image: 'https://onearth.studio/static/media/lowresonearthback.jpg',
      comment: 'Back redlight RGB PNG 3840x2400px 72dpi',
      forcheckout: 'Back redlight',
    },
    {
      id: '6',
      title: ' 3D On Earth Animation',
      subtitle: '58 Seconds HD Animation with Music',
      price: 75,
      image:
        'https://onearth.studio/static/media/oneminuteonearthanimation.jpg',
      comment:
        'Video QuickTime Apple ProRes 422 HQ (1,09 Go) 150 Mbit/s 1920x1080 24fps',
      forcheckout: '58 sec - With music',
    },
    {
      id: '4',
      title: ' 3D On Earth Animation',
      subtitle: 'HD Animation With Music Looped Three Times',
      price: 150,
      image: 'https://onearth.studio/static/media/onearthtriptyque.jpg',
      comment:
        'Video QuickTime Apple ProRes 422 HQ (3,24 Go) 150 Mbit/s 1920x1080 24fps',
      forcheckout: 'Looped three times - With music',
    },
    {
      id: '5',
      title: ' Adrian, Piña & Ava',
      subtitle: '1132 x 1130px Still Image',
      price: 8.5,
      image: 'https://onearth.studio/static/media/tatcycmam.jpg',
      comment: 'RGB PNG 144dpi',
      forcheckout: 'Still image',
    },
    {
      id: '8',
      title: " ON EARTH - Man's T-Shirt",
      subtitle: 'WHAT NEEDS TO BE DONE?',
      price: 34.5,
      image: 'https://onearth.studio/static/media/ts_man.jpg',
      comment:
        'Inscriptions and characters are printed only on the back of the t-shirt',
      forcheckout: 'What needs to be done?',
    },
    {
      id: '10',
      title: " ON EARTH - Woman's & Man's T-Shirt",
      subtitle: 'WHAT NEEDS TO BE DONE?',
      price: 59,
      image: 'https://onearth.studio/static/media/ts_manandwoman.jpg',
      comment:
        'Inscriptions and characters are printed only on the back of the t-shirts',
      forcheckout: 'What needs to be done?',
    },
    {
      id: '9',
      title: " ON EARTH - Woman's T-Shirt",
      subtitle: 'WHAT NEEDS TO BE DONE?',
      price: 34.5,
      image: 'https://onearth.studio/static/media/ts_woman.jpg',
      comment:
        'Inscriptions and characters are printed only on the back of the t-shirt',
      forcheckout: 'What needs to be done?',
    },
    {
      id: '7',
      title: ' Adrian, Piña & Ava - WHAT NEEDS TO BE DONE?',
      subtitle: '3000 x 4000px Still image',
      price: 12.5,
      image: 'https://onearth.studio/static/media/smallonearthwntbdstudio.jpg',
      comment: 'High Quality RGB PNG 3000x4000px 200dpi',
      forcheckout: 'Still image',
    },
  ],
}

export default data
