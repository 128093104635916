import React from 'react'
import { NavLink } from 'react-router-dom'
import ShoppingCart from './icons_components/ShoppingCart'
import './ShoppingCarts.css'

function ShoppingCarts() {
  return (
    <NavLink to='/shoppingcart'>
      <div className='shoppingcarts'>
        <div className='shoppingcarts__centered'>
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__orange' />
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__orange__bigger' />
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__orange' />
          <ShoppingCart className='shoppingcarticon__red' />
          <ShoppingCart className='shoppingcarticon__red' />
        </div>
      </div>
    </NavLink>
  )
}

export default ShoppingCarts
