import React from 'react'
import { NavLink } from 'react-router-dom'
import './Product.css'

function Product(props) {
  const { product, onAdd, onDetail } = props

  return (
    <div className='product'>
      <div key={product.id} className='product__info'>
        <p>
          <strong>{product.title}</strong>
        </p>
        <p>{product.subtitle}</p>
        <p>
          <small>$</small>
          <strong>{product.price.toFixed(2)}</strong>
        </p>
      </div>
      <NavLink to='/detail' onClick={() => onDetail(product)}>
        <img id='product__image' src={product.image} alt={product.subtitle} />
      </NavLink>

      <div className='display__comment'>{product.comment}</div>
      <button className='product__button' onClick={() => onAdd(product)}>
        ADD TO CART
      </button>
    </div>
  )
}

export default Product
