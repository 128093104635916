import React from 'react'
import './SideContainer.css'
import Socials from './Socials'

function SideContainer() {
  return (
    <div>
      <Socials
        socialsClassName='side__container'
        socialsClassNameOption01='socialsClassNameOption01'
        socialsClassNameOption02='socialsClassNameOption02'
        socialsClassNameOption03='socialsClassNameOption03'
        socialsClassNameOption04='socialsClassNameOption04'
        socialsClassNameOption05='socialsClassNameOption05'
        socialsClassNameOption06='socialsClassNameOption06'
        socialsClassNameOption07='socialsClassNameOption07'
      />
    </div>
  )
}

export default SideContainer
