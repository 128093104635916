import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { NavLink } from 'react-router-dom'
import './ShoppingCart.css'
import backgroundOnEarthStudio from './images/backgroundonearthstudio_02.png'

require('dotenv').config()

const stripePromise = loadStripe(process.env.REACT_APP_KEY)

function ShoppingCart(props) {
  const { cartItems, onAdd, onRemove } = props
  const itemsFloatPrice = cartItems.reduce(
    (accu, curr) => accu + curr.price * curr.qty,
    0
  )

  const itemsPrice = parseFloat(itemsFloatPrice.toFixed(2))
  const taxFloatPrice = itemsPrice * 0.14
  const taxPrice = parseFloat(taxFloatPrice.toFixed(2))

  // const shippingPrice = itemsPrice > 2000 ? 0 : 35

  const totalPrice = parseFloat(itemsPrice + taxPrice)

  const handleClick = async (event) => {
    const stripe = await stripePromise

    const line_items = cartItems.map((item) => ({
      price_data: {
        currency: 'usd',
        product_data: {
          name: item.title,
          images: [item.image],
        },
        unit_amount: Math.round(
          (item.price + item.price * 0.14).toFixed(2) * 100
        ),
        // unit_amount: Math.round(
        //   (item.price + item.price * 0.14).toFixed(2) * 100
        // ),
      },
      quantity: item.qty,
    }))

    // console.log('cartItems ::::::', cartItems)
    // console.log('line_items ::::::::', line_items)

    // Call your backend to create the Checkout Session
    const response = await fetch(
      '/create-checkout-session',
      // 'http://localhost:4242/create-checkout-session/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // priceId: Math.round(totalPrice * 100),
          // name: `${cartItemsName}`,
          line_items: line_items,
        }),
        mode: 'cors',
      }
    )

    const session = await response.json()

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    })

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  return (
    <div className='basket'>
      <img
        className='basket__image'
        src={backgroundOnEarthStudio}
        alt='background'
      />
      <div className='basket__container'>
        <h2 className='cart__title'>Cart Items</h2>
        <div>
          {cartItems.length === 0 && (
            <div className='cart__empty'>
              <NavLink to='/products' style={{ textDecoration: 'none' }}>
                <h2 className='your__shopping__cart__is__empty'>
                  <strong>
                    <i>Your shopping cart is empty</i>
                  </strong>
                </h2>
              </NavLink>
            </div>
          )}
        </div>
        {cartItems.map((item) => (
          <div key={item.id} className='cart__items__display'>
            <div className='items__images__descriptions'>
              <NavLink to='/products'>
                <img
                  className='items__images__size'
                  src={item.image}
                  alt={item.subtitle}
                />
              </NavLink>
              <div className='items__info'>
                <h5>{item.title}</h5>
                <h6>{item.subtitle}</h6>
                <h6>{item.comment}</h6>
              </div>
            </div>
            <div className='items__quantity__price'>
              <div>
                <strong>
                  {item.qty} x ${item.price.toFixed(2)}
                </strong>
              </div>
              <div className='plus__minus__buttons'>
                <button onClick={() => onAdd(item)} className='add'>
                  +
                </button>
                <button onClick={() => onRemove(item)} className='remove'>
                  -
                </button>
              </div>
            </div>
          </div>
        ))}
        {cartItems.length !== 0 && (
          <div className='all__checkout__prices'>
            <div className='move__to__the_left'>
              <div className='price__items__taxe__total'>
                {/* <div className='not__shift'>
                <div>for not shift</div>
                <div className='position__absolute'>
                  ${itemsPrice.toFixed(2)}
                </div>
              </div> */}
                <div className='items__price'>
                  <div className='items__price__alphanumeric'>
                    Items Price :&nbsp;
                  </div>
                  <div className='items__price__number'>
                    ${itemsPrice.toFixed(2)}
                  </div>
                </div>
                <div className='taxe__price'>
                  <div>Taxe Price :&nbsp;</div>
                  <div className='number__taxe__price'>
                    ${taxPrice.toFixed(2)}
                  </div>
                </div>
                <div className='total__price'>
                  <div>
                    <strong>Total Price :&nbsp;</strong>
                  </div>
                  <div className='number__total_price'>
                    <strong>${totalPrice.toFixed(2)}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <br />
              {''}
            </div>
            <button
              role='link'
              onClick={handleClick}
              className='pay__with__card'
            >
              <strong>Checkout</strong>
            </button>
            {/* </div> */}
          </div>
        )}
      </div>
    </div>
  )
}

export default ShoppingCart
