import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import './App.css'
import Header from './Header'
import Home from './Home'
import ShoppingCarts from './ShoppingCarts'
import Footer from './Footer'
import SideContainer from './SideContainer'
import ShoppingCart from './ShoppingCart'
import data from './data'
import ContactForm from './ContactForm'
import OurProducts from './OurProducts'
import About from './About'
import ProductDetail from './ProductDetail'

function App() {
  const { products } = data
  const [cartItems, setCartItems] = useState([])
  const [productDetail, setProductDetail] = useState([])
  // console.log('productDetail de App :', productDetail)
  const itemsTotalNumber = cartItems.reduce((accu, curr) => accu + curr.qty, 0)

  const onDetail = (product) => {
    setProductDetail(product)
  }

  const onAdd = (product) => {
    // console.log('from onAdd from App.js :', product)
    const exist = cartItems.find((x) => x.id === product.id)
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
        )
      )
    } else {
      setCartItems([...cartItems, { ...product, qty: 1 }])
    }
  }

  const onRemove = (product) => {
    const exist = cartItems.find((x) => x.id === product.id)
    if (exist.qty === 1) {
      setCartItems(cartItems.filter((x) => x.id !== product.id))
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
        )
      )
    }
  }

  return (
    <div className='App'>
      <Header itemsTotalNumber={itemsTotalNumber} />
      <Switch>
        <Route path='/about'>
          <ScrollToTop />
          <About />
          <SideContainer />
          <ShoppingCarts />
        </Route>
        <Route path='/products'>
          <ScrollToTop />
          <OurProducts products={products} onAdd={onAdd} onDetail={onDetail} />
          <ShoppingCarts />
          <SideContainer />
        </Route>
        <Route path='/contact'>
          <ScrollToTop />
          <ContactForm />
          <SideContainer />
          <ShoppingCarts />
        </Route>
        <Route path='/shoppingcart'>
          <ScrollToTop />
          <ShoppingCart
            onAdd={onAdd}
            onRemove={onRemove}
            cartItems={cartItems}
          />
          <SideContainer />
        </Route>
        <Route path='/detail'>
          <ScrollToTop />
          <ProductDetail productDetail={productDetail} onAdd={onAdd} />
          <SideContainer />
          <ShoppingCarts />
        </Route>
        <Route exact path='/'>
          <ScrollToTop />
          <Home products={products} onAdd={onAdd} onDetail={onDetail} />
          <SideContainer />
          <ShoppingCarts />
        </Route>
      </Switch>
      <Footer />
    </div>
  )
}

export default App
