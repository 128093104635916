import React, { Fragment } from 'react'
import './OurProducts.css'
import backgroundOnEarthStudio from './images/backgroundonearthstudio_02.png'
import Product from './Product'

function OurProducts(props) {
  const { products, onAdd, onDetail } = props
  return (
    <Fragment>
      <div className='products'>
        <img
          className='products__image'
          src={backgroundOnEarthStudio}
          alt='background'
        />
        <div className='products__container'>
          <div className='home__products__container'>
            <h2 className='our__products__title'>Our Products</h2>
            <div className='home__row__container'>
              {products.slice(0, 10).map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  onAdd={onAdd}
                  onDetail={onDetail}
                />
              ))}
            </div>
            {/* <div className='home__row__container'>
              {products.slice(0, 3).map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  onAdd={onAdd}
                  onDetail={onDetail}
                />
              ))}
            </div> */}
            {/* <div className='home__row__container'>
              {products.slice(4, 7).map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  onAdd={onAdd}
                  onDetail={onDetail}
                />
              ))}
            </div> */}
            {/* <div className='home__row__container'>
              {products.slice(7, 9).map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  onAdd={onAdd}
                  onDetail={onDetail}
                />
              ))}
            </div> */}
            {/* <div className='home__row__container'>
              {products.slice(9, 10).map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  onAdd={onAdd}
                  onDetail={onDetail}
                />
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default OurProducts
