import React from 'react'
import './Socials.css'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaGithub } from 'react-icons/fa'
import { SiTiktok } from 'react-icons/si'
import { FaFacebookF } from 'react-icons/fa'

function Socials({
  socialsClassName,
  socialsClassNameOption01,
  socialsClassNameOption02,
  socialsClassNameOption03,
  socialsClassNameOption04,
  socialsClassNameOption05,
  socialsClassNameOption06,
  socialsClassNameOption07,
}) {
  return (
    <div>
      <div className={socialsClassName}>
        <a
          href='https://twitter.com/OnEarthStudio'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaTwitter className={socialsClassNameOption01} />
        </a>
        <a
          href='https://www.instagram.com/dodesproduction/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaInstagram className={socialsClassNameOption02} />
        </a>
        <a
          href='https://www.youtube.com/watch?v=84xS7zBa9zg'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaYoutube className={socialsClassNameOption03} />
        </a>
        <a
          href='https://www.linkedin.com/in/vrana-jan'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaLinkedin className={socialsClassNameOption04} />
        </a>
        <a
          href='https://www.tiktok.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <SiTiktok className={socialsClassNameOption05} />
        </a>
        <a
          href='https://www.facebook.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaFacebookF className={socialsClassNameOption06} />
        </a>
        <a
          href='https://www.github.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaGithub className={socialsClassNameOption07} />
        </a>
      </div>
    </div>
  )
}

export default Socials
