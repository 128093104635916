import React from 'react'
import { NavLink } from 'react-router-dom'
import backgroundOnEarthStudio from './images/backgroundonearthstudio_02.png'
import './Home.css'
import Product from './Product'
import ReactPlayer from 'react-player/youtube'
// import ReactPlayer from 'react-player'

function Home(props) {
  const { products, onAdd, onDetail } = props
  return (
    <div className='home__page'>
      <img
        className='home__image'
        src={backgroundOnEarthStudio}
        alt='background'
      />
      <div className='home__container'>
        <NavLink to='/contact' style={{ textDecoration: 'none' }}>
          <h1 className='onearthwhatneeds__top'>
            ON EARTH WHAT NEEDS TO BE DONE?
          </h1>
        </NavLink>
        <h1></h1>
        <div className='home__video__container'>
          <ReactPlayer
            className='react__player'
            url='https://youtu.be/52pPn2jc4OA'
            playing='true'
            width='100%'
            height='100%'
            muted='true'
            controls='true'
            loop='true'
          />
        </div>
        <br />
        {''}
        <br />
        {''}
        <div className='thepurpose__container'>
          <div className='horizontal__ligne__02'>{''}</div>
          <NavLink to='/products' style={{ textDecoration: 'none' }}>
            <h2 className='thepurpose__of'>
              THE PURPOSE OF THIS WEBSITE IS TO SHOWCASE A FULLY RESPONSIVE
              SHOPPING WEBSITE
            </h2>
          </NavLink>
          <div className='horizontal__ligne__02'>{''}</div>
        </div>
        <br />
        {''}
        <br />
        {''}
        <div className='horizontal__ligne'>{''}</div>
        <p>
          {''}
          Like{' '}
          <a
            className='stripe__link'
            href='https://www.facebook.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>Facebook</strong>
          </a>
          ,{' '}
          <a
            className='stripe__link'
            href='https://www.instagram.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>Instagram</strong>
          </a>
          ,{' '}
          <a
            className='stripe__link'
            href='https://www.netflix.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>Netflix</strong>
          </a>
          ,{' '}
          <a
            className='stripe__link'
            href='https://www.uber.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>Uber</strong>
          </a>
          ,{' '}
          <a
            className='stripe__link'
            href='https://www.airbnb.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>Airbnb</strong>
          </a>
          ,{' '}
          <a
            className='stripe__link'
            href='https://www.nytimes.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>The New York Times</strong>
          </a>
          ,{' '}
          <a
            className='stripe__link'
            href='https://www.cnn.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>CNN</strong>
          </a>
          ,... the UI of onearth.studio was built with {''}
          <a
            className='stripe__link'
            href='https://reactjs.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>
              <i>React.js</i>
            </strong>
          </a>{' '}
          <br />
          {''}
          <br />
          {''}
          Of course you can buy all products displayed on this website, it
          wouldn't be a shopping website if you couldn't buy those items. The
          payments are processed via{' '}
          <a
            className='stripe__link'
            href='https://stripe.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            Stripe
          </a>{' '}
          an online payment processing and credit card processing platform for
          businesses. {''}Beside the item:
          <NavLink to='/about' className='this__shopping__website'>
            <strong>
              {' '}
              <i>THIS SHOPPING WEBSITE Customized for You </i>
            </strong>
          </NavLink>
          {''}the other items are presented as demonstration purpose but as this
          is a shopping website it would make no sense if you wouldn't be able
          to really buy all the products of this website. So, if you wish to buy
          anything you can do so and we will provide it to you.
          <br />
          {''}
          <br />
          {''}
          If you are one of the first to find{' '}
          <strong>
            <i>Piña</i>
          </strong>{' '}
          in this website you will get a still image of this caracter, personal
          use only. When you find it please send us a message via the Contact
          Form and we will send it to you.
          <br />
          {''}
          <br />
          {''}
          <NavLink to='/about' className='this__shopping__website'>
            <strong>
              {' '}
              <i>THIS FULLY RESPONSIVE SHOPPING WEBSITE Customized for You </i>
            </strong>
          </NavLink>{' '}
          have
          <strong>
            {' '}
            <i>five</i>
          </strong>{' '}
          sections :{' '}
          <i>
            Home (onearth.studio's logo), Our Products, About Us, Contact and a
            Shopping Cart.
          </i>{' '}
          On the right side and in the footer are up to
          <strong>
            {' '}
            <i>seven</i>
          </strong>{' '}
          links to socials. When customized for you, this whole website will
          become yours with{' '}
          <i>
            your domain, your content, your logo, your brand colors, your
            products, your contact informations and your socials.
          </i>{' '}
          You can customize it as needed, you can add any informations about
          your compagny instead of this whole text. We will take care of
          everything:{''}
          <NavLink to='/about' className='the__frontend'>
            <strong> the frontend</strong>
          </NavLink>
          ,
          <NavLink to='/shoppingcart' className='the__backend'>
            <strong> the backend </strong>
          </NavLink>
          and if don't have a domain we will help you to register one. In the
          end you will have a website like this one but it will be totally
          changed and it will not look like what you see now but it will have
          only your graphical elements, it will be your website.
          <br />
          {''}
          <br />
          {''}
          The price of
          <NavLink to='/about' className='this__shopping__website'>
            <strong>
              {' '}
              <i>THIS SHOPPING WEBSITE Customized for You </i>
            </strong>
          </NavLink>{' '}
          is <small>$</small>
          <strong>3900</strong> but you will get <strong>50%</strong> discount{' '}
          <small>
            {'('}
            <strike>$</strike>
          </small>
          <strong>
            <strike>3900</strike>
          </strong>
          {')'} <small>$</small>
          <strong>1950</strong> if you are one of the first{' '}
          <strong>
            <i>eighteen</i>
          </strong>{' '}
          buyers.
          <br />
          {''}
          <br />
          Before purshasing{' '}
          <NavLink to='/about' className='this__shopping__website'>
            <strong>
              {' '}
              <i>THIS SHOPPING WEBSITE Customized for You </i>
            </strong>
          </NavLink>{' '}
          please contact us via the <i>Contact Form</i> and we will reach to you
          ASAP, if you have any questions, feel free to ask.
          <br />
          {''}
          <br />
          If you need less or if you need more than the whole content of this
          website, contact us!
          <br />
          {''}
          <br />
          {''}
          <br />
          {''}
        </p>
        <div className='horizontal__ligne'>{''}</div>
        <p>
          <i>
            "A Customer is the most important visitor on our premises. He is not
            dependent on us. We are dependent on him. He is not an interruption
            on our work. He is the purpose of it. He is not an outsider on our
            business. He is a part of it. We are not doing him a favour by
            serving him. He is doing us a favour by giving us an opportunity to
            do so."
          </i>
          <br />
          {''}
          <a
            className='gandhi__margintop__link'
            href='https://en.wikipedia.org/wiki/Mahatma_Gandhi'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>
              <small>Mohandas Karamchand Gandhi - 1890</small>
            </strong>
          </a>
        </p>
        <div className='horizontal__ligne'>{''}</div>
        {''} <br />
        {''} <br />
        {''}
        <div className='horizontal__ligne'>{''}</div>
        <p>
          Below you'll find all the products on sale on this website, those
          products are the same as the ones in the <i>Our Products</i> section,
          everything can be moved, can be changed, this website will be
          customized for you to match your needs.
          <br />
          {''}
        </p>
        <div className='horizontal__ligne'>{''}</div>
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <div className='home__row__container'>
          {products.slice(0, 10).map((product) => (
            <Product
              key={product.id}
              product={product}
              onAdd={onAdd}
              onDetail={onDetail}
            />
          ))}
        </div>
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <br />
        {''}
        <div className='horizontal__ligne'>{''}</div>
        <NavLink to='/contact' style={{ textDecoration: 'none' }}>
          <p className='onearthwhatneeds__bottom'>
            On earth what needs to be done?
          </p>
        </NavLink>
      </div>
    </div>
  )
}

export default Home
