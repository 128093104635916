import React from 'react'
import { NavLink } from 'react-router-dom'
import './Header.css'
import Navbar from './Navbar'
import logoOnEarthStudio from './logo/logoOnEarthStudio.png'
import ShoppingCart from './icons_components/ShoppingCart'

function Header(props) {
  const { itemsTotalNumber } = props
  return (
    <header>
      <div className='header__layout'>
        <NavLink to='/'>
          <img
            className='header__logo'
            src={logoOnEarthStudio}
            alt='logo on earth studio'
          />
        </NavLink>

        <Navbar
          navBarClassName='ourcompany__ourproducts__contact'
          navBarClassNameOption='navbar__option'
          navLinkClassName='nav__link__header'
        />
        <NavLink to='/shoppingcart' style={{ textDecoration: 'none' }}>
          <div className='header__icon__number__item'>
            <ShoppingCart className='header__shopping__cart__icon' />
            <div className='header__shopping__number__item'>
              <div className='header__shopping__number'>
                ({itemsTotalNumber})
              </div>
              <div className='header__shopping__item'>
                {itemsTotalNumber !== 1 ? 'items' : 'item'}
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    </header>
  )
}

export default Header
