import React from 'react'
import { NavLink } from 'react-router-dom'
import Navbar from './Navbar'
import './Footer.css'
import Socials from './Socials'

function Footer() {
  return (
    <footer>
      <div className='footer__layout'>
        <NavLink to='/contact' style={{ textDecoration: 'none' }}>
          <p className='footer__layout__copyright'>
            On Earth Studio © {new Date().getFullYear()}
          </p>
        </NavLink>
        <div>
          <Navbar
            navBarClassName='footer__navbar'
            navBarClassNameOption='footer__textdecoration'
            navLinkClassName='nav__link__footer'
          />
        </div>
        <div>
          <Socials
            socialsClassName='footer__socials'
            socialsClassNameOption01='socialsClassNameOption01f'
            socialsClassNameOption02='socialsClassNameOption02f'
            socialsClassNameOption03='socialsClassNameOption03f'
            socialsClassNameOption04='socialsClassNameOption04f'
            socialsClassNameOption05='socialsClassNameOption05f'
            socialsClassNameOption06='socialsClassNameOption06f'
            socialsClassNameOption07='socialsClassNameOption07f'
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer
